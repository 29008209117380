
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.app-tab {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 30px;
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.05);
  color: $grey-500;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: $short;

  &:first-child {
    border-radius: 10px 0 0;
  }

  &:last-child {
    border-radius: 0 10px 0 0;
  }

  &--active {
    z-index: 1;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.05), -1px 0 3px 0 rgba(0, 0, 0, 0.05);
    color: $red-400;
  }

  &:hover,
  &--active {
    background: white;
  }

  &__icon {
    display: flex;
    margin-right: 12px;

    svg {
      width: 18px;
    }
  }

  &__text {
    user-select: none;
    white-space: nowrap;
  }
}
