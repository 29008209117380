
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.fade-enter-active,
.fade-leave-active {
  transition: opacity $short;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
