
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.agent-card {
  overflow: hidden;
  min-height: 142px;
  flex: 1;
  padding: 0 10px;

  &__avatar {
    margin: 0 auto 6px;
  }

  &__name {
    min-height: 15px;
    font-size: 13px;
    text-align: center;
  }

  &__position {
    min-height: 14px;
    margin-bottom: 6px;
    color: $grey-400;
    font-size: 12px;
    text-align: center;
  }

  &__email {
    @include ellipsis;

    display: block;
    margin-bottom: 4px;
    color: $blue-400;
    font-size: 12px;
    text-align: center;
  }

  &__phone {
    display: block;
    color: $black;
    font-size: 12px;
    text-align: center;
  }
}

.avatar {
  display: flex;
  width: 34px;
  height: 34px;
  min-height: 34px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

  &__image {
    display: flex;
    width: 34px;
    min-width: 34px;
    height: 34px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fedd46;
    color: $black;
    font-size: 14px;
    font-weight: 700;
    object-fit: cover;
  }
}

.slider {
  display: flex;
  align-items: flex-start;

  &__button {
    width: 24px;
    align-self: center;
    color: $grey-600;
    cursor: pointer;
    transition: color $shortest;
    user-select: none;

    &:hover {
      color: $grey-700;
    }

    &.blocked {
      opacity: 0.1;
      pointer-events: none;
      user-select: none;
    }
  }
}
