
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */

@mixin button($size: "small", $theme: "light") {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  &__icon {
    display: flex;
  }

  @if $size == "small" {
    height: 34px;
    font-size: 13px;

    &__icon {
      width: 13px;
      margin-right: 7px;
    }
  }

  @if $size == "big" {
    height: 42px;
    font-size: 16px;

    &__icon {
      width: 16px;
      margin-right: 10px;
    }
  }

  @if $theme == "light" {
    border: 1px solid #ececec;
    background: white;
    color: #6b6b6b;
  }

  @if $theme == "dark" {
    background: #fddf4d;
    color: #414141;
    font-weight: 700;
  }
}

$yellow: #fddf4d;

.confirmation {
  &__body {
    position: relative;
    padding: 18px;
    border-radius: 10px;
    margin-bottom: 10px;

    .offer-head & {
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 0;
      margin-left: 100px;
    }

    &--step {
      &--one {
        padding: 15px;
        background: $yellow;

        .offer-head & {
          background: white;
        }
      }

      &--two {
        border: 2px dashed $yellow;
        background: #fffefb;
        text-align: center;

        .offer-head & {
          border: none;
          text-align: left;
        }
      }

      &--three {
        border: 2px dashed $yellow;
        background: #fffcf0;

        .offer-head & {
          border: none;
        }
      }

      &--four {
        border: 2px dashed #e0e0e0;
        background: white;

        .offer-head & {
          border: none;
        }
      }
    }
  }

  &__icon {
    display: block;
    width: 80px;
    margin: 0 auto 8px;

    .offer-head & {
      display: none;
    }

    .offer-head .confirmation__body--step--four & {
      display: block;
      width: 44px;
      margin-bottom: 0;
    }
  }

  &__text {
    color: #414141;

    .offer-head & {
      width: 394px;
      font-size: 14px;
      font-weight: 400;

      span {
        color: #414141;
        font-weight: 700;
      }
    }

    &--step {
      &--one {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 700;

        .offer-head & {
          margin-right: 60px;
          margin-bottom: 0;
          color: #9c9c9c;
        }
      }

      &--two {
        margin-bottom: 9px;
        font-size: 14px;

        .offer-head & {
          margin-bottom: 4px;
          font-size: 13px;
        }
      }

      &--four {
        font-size: 14px;
        text-align: center;

        .offer-head & {
          width: unset;
          padding: 0 20px;
        }
      }
    }
  }

  &__info {
    margin-bottom: 10px;
    color: #848484;
    font-size: 12px;

    .offer-head & {
      margin-bottom: 0;
      font-size: 10px;
    }

    @keyframes blink {
      from {
        color: white;
      }

      to {
        color: red;
      }
    }

    &--error {
      animation: blink 0.3s steps(5, start) infinite;
    }
  }

  &__hidden-input {
    display: none;
  }

  &__file {
    width: 100%;
    height: 36px;
    margin-bottom: 6px;

    .offer-head & {
      width: 240px;
      height: 42px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
  }

  .offer-head &__wrapper-for-fourth-step {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.button {
  @include button("small", "dark");

  .offer-head & {
    @include button("big", "dark");
  }
}

.download-button {
  @include button("big", "light");

  height: 36px;
  color: #414141;
  font-weight: 700;

  .offer-head & {
    @include button("big", "dark");
  }
}

.footer-button {
  @include button("small");

  border: 1px solid #ececec;
  background: white;
  color: #6b6b6b;

  .offer-head & {
    display: none;
  }

  .offer-head .confirmation__body--step--four ~ & {
    display: flex;
  }
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  &__name {
    overflow: hidden;
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__dismiss {
    margin-right: 12px;
    cursor: pointer;
  }
}
