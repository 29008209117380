
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.root {
  position: relative;

  &__image,
  &__initials {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: cover;

    &--loading {
      filter: blur(1px);
      opacity: 0.5;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 46px;
    transform: translate(-50%, -50%);
  }

  &__initials {
    background: #fedd46;
    color: $black;
    font-size: 36px;
    font-weight: 500;
  }

  &__input {
    position: absolute;
    right: 3px;
    bottom: 0;
    display: flex;
    width: 27px;
    height: 27px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
    background: black;
    cursor: pointer;

    img {
      width: 15px;
      opacity: 0.7;
      transition: opacity $shortest;
    }

    &:hover img {
      opacity: 1;
    }
  }

  &__hidden-input {
    display: none;
  }
}
