// colors
$black: #1c1c1c;

$grey-800: #333;
$grey-700: #6b6b6b;
$grey-600: #828282;
$grey-500: #939393;
$grey-400: #a7a7a7;
$grey-300: #dcdcdc;
$grey-200: #e4e4e4;
$grey-100: #f5f5f5;
$grey-50: #f9f9f9;

$red-500: #f75837;
$red-450: #ef5350;
$red-400: #de1010;

$salmon: #fd866c;

$lime-500: #04c200;

$blue-500: #5383ff;
$blue-400: #6398e9;
$message-link: #3072c4;

$yellow-500: #fedd46;
$yellow-400: #fee25f;
$yellow-300: #ffea88;
$yellow-200: #fff8d8;

// time periods
$shortest: 0.16s;
$short: 0.2s;
$long: 0.5s;

// layout
$navigation-drawer-width--opened: 288px;
$navigation-drawer-width--folded: 56px;
