
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.select {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  &__selected-option {
    display: flex;
    height: 24px;
    align-items: center;
    border-bottom: 1px solid $grey-300;
    cursor: pointer;
    font-size: 14px;
  }

  &__input {
    width: 18px;
    outline: none;

    &:last-child {
      width: 20px;
      padding-left: 2px;
    }
  }

  &__options {
    position: absolute;
    z-index: 1;
    overflow: auto;
    max-width: 174px;
    max-height: 130px;
    padding: 6px 0;
    border-radius: 6px;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    scrollbar-width: thin;
    transform: translateY(30px);
    transition: opacity $shortest;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-200;
    }

    &--opened {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background: $grey-100;
    }

    &--selected {
      font-weight: 700;
    }

    &--forbidden {
      color: $grey-600;
      pointer-events: none;
    }
  }
}
