
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.app-modal {
  position: absolute;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.3);
  }

  &__content {
    position: absolute;
    display: flex;
    border-radius: 6px;
    margin: 12px;
    animation-duration: $short;
    animation-name: zoom-in;
    background-color: white;
  }
}

.app-modal-content {
  position: relative;
  display: flex;
  width: calc(100% - 2 * 12px);
  max-height: calc(100% - 2 * 12px);
  flex-direction: column;
  padding: 20px 4px 20px 20px;

  &--clean {
    padding: 0;

    & > * {
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &__header {
    padding-right: 36px;
    padding-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
  }

  &__subheader {
    padding-right: 16px;
  }

  &__body {
    padding-right: 16px;
    overflow-y: auto;
    scrollbar-color: $grey-300 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-300;
    }
  }

  @media (max-width: 600px) {
    &__header {
      padding-bottom: 10px;
    }
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}
