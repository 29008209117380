
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.not-found {
  display: flex;
  justify-content: center;
  font-size: 36px;
}
