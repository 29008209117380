
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.question-textarea {
  max-width: 800px;
}

.question-mark {
  position: relative;
  left: 4px;
  cursor: pointer;
  pointer-events: all;

  * {
    position: relative;
  }

  &::before {
    position: absolute;
    top: -2px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 4px);
    border-radius: 10px;
    background-color: #ffe676;
    content: "";
  }
}
