
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.login-form {
  display: flex;
  width: 332px;
  max-width: 96%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    margin-bottom: 60px;
  }

  &__username-input {
    margin-bottom: 40px;
  }

  &__password-input {
    margin-bottom: 6px;
  }

  &__password-icon {
    width: 20px;
    fill: $grey-400;
  }

  &__password-restore {
    align-self: flex-end;
    margin-bottom: 36px;
    font-size: 12px;
  }

  &__login-button {
    @include gradient-button;

    position: relative;
    margin-bottom: 12px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }

  &__call-to-action {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__suggestion {
    color: $grey-600;
    font-size: 12px;
  }

  &__link {
    color: #5383ff;
    font-size: 12px;
  }
}
