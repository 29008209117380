
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.items-box {
  overflow: hidden;
  padding: 12px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);

  &--hidden {
    height: 0;
    padding: 0;
  }

  &__items {
    height: 100%;
    min-height: 32px;
    overflow-y: auto;
    scrollbar-color: $grey-200 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-200;
    }
  }

  &__item {
    display: flex;
    height: 32px;
    align-items: center;
    padding-left: 8px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      background: $grey-100;
    }
  }
}
