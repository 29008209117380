
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.cab-tooltip {
  position: relative;
  display: inline-block;

  &__activator {
    display: flex;
    cursor: pointer;
  }

  &__body {
    z-index: 2;
    display: none;
    border-radius: 6px;
    background: white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    font-weight: 400;
  }

  &__body[data-show] {
    display: block;
  }

  &__body[data-show].hidden {
    display: none;
  }

  &__arrow {
    position: absolute;
    right: -10px;
    width: 8px;
    height: 8px;
    visibility: hidden;
  }

  &__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: white;
    content: "";
    transform: rotate(45deg);
    visibility: visible;
  }

  &__continer {
    padding: 12px;
    line-height: 1.4;
  }

  &__interactive {
    position: absolute;
    z-index: -1;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: transparent;
  }
}

[data-popper-placement^="top"] > .cab-tooltip__arrow {
  bottom: -4px;
}

[data-popper-placement^="bottom"] > .cab-tooltip__arrow {
  top: -4px;
}

[data-popper-placement^="left"] > .cab-tooltip__arrow {
  right: 4px;
}

[data-popper-placement^="right"] > .cab-tooltip__arrow {
  left: -4px;
}

[data-popper-placement$="end"] > .cab-tooltip__arrow {
  // left: -4px !important;
}
