
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.tariff-outsourcer {
  padding: 24px;

  &__title {
    margin-bottom: 60px;
    font-size: 28px;
    font-weight: 500;
  }

  &__members {
    display: flex;
    flex-wrap: wrap;
  }

  &__member {
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 8px;
    margin-right: 24px;
    margin-bottom: 24px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 1264px) {
    &__members {
      justify-content: space-around;
    }

    &__member {
      margin-right: 20px;
      margin-bottom: calc(max((100% - 280px * 3) / 3, 20px));
    }
  }

  @media (max-width: 960px) {
    &__title {
      margin-bottom: 30px;
      font-size: 20px;
    }

    &__member {
      margin-bottom: calc(max((100% - 280px * 2) / 2 - 20px, 20px));
    }
  }

  @media (max-width: 600px) {
    &__title {
      margin-bottom: 20px;
      font-size: 18px;
    }

    &__member {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.outsourcer-details {
  display: grid;
  margin-top: 35px;
  font-size: 15px;
  font-weight: 400;
  grid-template-columns: 35% 15% 15% 15% 20%;
  line-height: 26px;

  &__block {
    &__header {
      background-color: #f9f9f9;
      color: #848484;

      @media (max-width: 1410px) {
        & {
          height: 79px;
        }
      }
    }

    &__body {
      color: #1c1c1c;

      &.email {
        display: block;
        width: 100%;
        padding: 16px 0 11px;
        color: #528fc9;
        text-align: center;
      }
    }

    p {
      width: 100%;
      padding: 16px 0 11px;
      text-align: center;
    }
  }

  @media (max-width: 850px) {
    & {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 1fr);

      &__block {
        display: flex;
      }
    }
  }
}

.outsourcer-member {
  text-align: center;

  &__avatar {
    overflow: hidden;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  &__photo {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__initials {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #fedd46;
    font-size: 26px;
    font-weight: 500;
  }

  &__name {
    margin-bottom: 6px;
  }

  &__role {
    margin-bottom: 16px;
    color: $grey-600;
    font-size: 13px;
  }

  &__email {
    @include ellipsis;

    max-width: 100%;
    color: $blue-500;
  }
}

.tariff-contract-none {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon-wr {
    display: flex;
    width: 190px;
    height: 190px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 30px;
    background-color: #ebebeb;
  }

  &__icon {
    color: #b7b7b7;
  }

  &__title {
    margin-bottom: 10px;
    color: #333;
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }

  &__description {
    max-width: 400px;
    color: #848484;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}
