.app-hint {
  &__body {
    z-index: 1;
    display: none;
    max-width: 240px;
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
    color: $grey-700;
    font-size: 12px;
    white-space: initial;

    &[data-show] {
      display: block;
    }
  }

  &__arrow {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    visibility: hidden;

    &::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      content: "";
      transform: rotate(45deg);
      visibility: visible;

      [data-popper-placement^="top"] > & {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
      }

      [data-popper-placement^="bottom"] > & {
        box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
      }
    }

    [data-popper-placement^="top"] > & {
      bottom: -4px;
    }

    [data-popper-placement^="bottom"] > & {
      top: -4px;
    }

    // #tooltip[data-popper-placement^="left"] > #arrow {
    //   right: -4px;
    // }

    // #tooltip[data-popper-placement^="right"] > #arrow {
    //   left: -4px;
    // }

    [data-popper-placement="top-start"] > & {
      // HACK: зачем-то popper для start ставит translate(XXXpx, 0)
      transform: translateX(0) !important;
      left: 8px !important;
    }
  }
}
