
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.cab-textarea {
  &__wrapper {
    position: relative;
    overflow: auto;
    padding-top: 14px;
    border-bottom: 1px solid $grey-300;

    &--active {
      border-bottom: 1px solid $black;
    }

    &--error {
      border-bottom: 1px solid $red-500;
    }
  }

  &__textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    scrollbar-color: $grey-300 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-300;
    }

    &::placeholder {
      opacity: 0;
      transition: opacity $short;
    }

    &--visible-placeholder {
      &::placeholder {
        opacity: 1;
      }
    }
  }

  &__label {
    position: absolute;
    top: 10px;
    left: 0;
    color: $grey-600;
    cursor: pointer;
    transition: $shortest;

    &--active {
      top: -2px;
      font-size: 12px;
    }
  }

  &__wrapper--disabled {
    border-bottom: 1px solid $grey-300;
    pointer-events: none;
  }

  &__wrapper--disabled &__label {
    color: $grey-400;
  }

  &__wrapper--disabled &__textarea {
    color: $grey-500;
  }

  &__error-message {
    margin-top: 6px;
    color: $red-500;
    font-size: 12px;
    white-space: pre-line;
  }

  &__textarea-wrapper {
    position: relative;
  }

  &__highlight {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__highlight-text {
    position: relative;
    left: 4px;

    * {
      position: relative;
    }

    &::before {
      position: absolute;
      top: -2px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 4px);
      border-radius: 10px;
      background-color: #ffe676;
      content: "";
    }
  }
}
