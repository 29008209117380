
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.eye-icon {
  width: 16px;
  cursor: pointer;
  fill: $grey-700;
  user-select: none;
}
