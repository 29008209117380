
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.input-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input-radio--disabled {
  cursor: default;

  & * {
    filter: grayscale(1);
    opacity: 0.8;
  }
}

.input-radio__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.input-radio__radio {
  display: flex;
  width: 18px;
  height: 18px;
  flex: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  border-radius: 100%;
  margin-right: 12px;
}

.input-radio__radio--checked {
  border: none;
  background: $yellow-500;

  &::before {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $grey-800;
    content: "";
  }
}
