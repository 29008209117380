
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.block {
  &__button {
    display: flex;
    width: 232px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border: 1px solid $grey-200;
    border-radius: 6px;

    &.blocked {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  @media (max-width: 960px) {
    &__button {
      width: 300px;
    }
  }

  @media (max-width: 600px) {
    &__button {
      width: 340px;
      padding: 10px 20px;
    }
  }
}

.button {
  cursor: pointer;
  user-select: none;

  &__text {
    color: $grey-600;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__icon {
    display: flex;
    width: 32px;
    min-width: 32px;
    height: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-200;
    border-radius: 50%;
    background-color: white;
  }

  &:hover {
    background: linear-gradient(to right, #ffdb33, #ffba00);
  }

  &:hover &__text {
    color: $black;
  }
}

.modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
  }

  &__input {
    margin-top: 20px;
    margin-bottom: 35px;
  }

  &__cancel,
  &__save {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__cancel {
    width: 130px;
    margin-right: 8px;
    color: $grey-700;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: $grey-800;
    }
  }

  &__save {
    width: 270px;
    height: 40px;
  }

  @media (max-width: 450px) {
    &__buttons {
      flex-direction: column-reverse;
    }

    &__cancel,
    &__save {
      width: 100%;
    }

    &__save {
      margin-bottom: 20px;
    }
  }
}
