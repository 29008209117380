
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.table {
  &__head {
    display: flex;
    align-items: center;
    padding: 9px 24px;
    background-color: $grey-50;
    cursor: pointer;
    font-weight: 700;
    user-select: none;

    svg {
      width: 16px;
      min-width: 16px;
      margin-left: 8px;
    }
  }

  &__row {
    display: flex;
    padding: 9px 0;
    border-bottom: 1px solid $grey-100;
    margin: 0 24px;

    &--key {
      display: flex;
      width: 360px;
      align-items: center;
    }

    &--value {
      display: flex;
      min-height: 24px;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(2) {
        margin-left: 48px;
      }

      &:last-child {
        margin-right: 48px;
      }
    }
  }

  &__icon {
    width: 24px;
  }

  @media (min-width: 1265px) and (max-width: 1904px) {
    // linear equation:
    // 1904*x1 + x2 = 360
    // 1265*x1 + x2 = 300
    &__row--key {
      width: calc(10vw + 180px);
    }
  }

  @media (min-width: 961px) and (max-width: 1264px) {
    // linear equation:
    // 1264*x1 + x2 = 360
    // 961*x1 + x2 = 300
    &__row--key {
      width: calc(20vw + 110px);
    }
  }

  @media (max-width: 960px) {
    &__head {
      font-size: 12px;
    }

    &__row {
      flex-wrap: wrap;

      &--key {
        width: 100%;
        margin-bottom: 10px;
        color: $grey-700;
        font-size: 12px;
      }

      &--value {
        &:nth-child(2) {
          margin-left: 24px;
        }

        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }
}
