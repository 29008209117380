
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.cab-input {
  width: 100%;
  height: 100%;
  padding: 0 2px 4px;
  background: transparent;
  outline: none;

  &::placeholder {
    opacity: 0;
    transition: opacity $short;
  }

  &--visible-placeholder {
    &::placeholder {
      opacity: 1;
    }
  }
}
