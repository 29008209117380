
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.history-action {
  display: inline-flex;
  align-items: center;
  color: #848484;
  cursor: pointer;
  font-size: 16px;
  transition: 0.2s;

  &:hover {
    color: #6b6b6b;
  }
}

.history-icon {
  margin-right: 9px;
  color: #6398e9;
}

.history-tooltip {
  width: max-content;
  max-width: 430px;
}

.history-list {
  max-height: 400px;
}

.history-item-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  margin: 5px 0;
}

.history-item-left {
  margin-right: 20px;
}

.history-item-name {
  margin-bottom: 4px;
  color: #3072c4;
  font-size: 15px;
  line-height: 18px;
}

.history-item-date {
  color: #848484;
  font-size: 12px;
  line-height: 15px;
}

.history-item-download {
  margin-left: auto;
  color: #848484;
}

.scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d6d6d6;
  }

  /* HACK: для safari */
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* HACK: для safari */
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button:decrement,
  &::-webkit-scrollbar-button:increment {
    display: none;
  }
}
