
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.block {
  &__faux-input {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
}

.faux-input {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $grey-600;
  color: $grey-600;

  &__label {
    min-width: 100%;
    margin-bottom: 8px;
    font-size: 12px;
  }

  &__value {
    @include ellipsis;

    width: calc(100% - 16px);
    margin-bottom: 10px;
    font-size: 15px;
  }

  &__icon {
    width: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity $shortest;

    &:hover {
      opacity: 1;
    }
  }
}

.modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
  }

  &__input {
    margin-top: 20px;
  }

  &__cancel {
    display: flex;
    width: 130px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: $grey-700;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
      color: $grey-800;
    }
  }

  &__save {
    width: 130px;
    height: 40px;
  }
}
