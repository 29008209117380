
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.reference-faq {
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 20px;
    }
  }
}

.expand-panel {
  width: 100%;
  border-bottom: 1px solid $grey-200;
  font-size: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    font-weight: 500;

    img {
      height: 30px;
      margin-left: 20px;
    }

    &--active img {
      transform: rotate(180deg);
    }
  }

  &__content {
    max-width: 900px;
    padding-bottom: 20px;
    color: $grey-700;

    a {
      color: $blue-500;
    }
  }
}
