
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.view {
  width: 100%;
  flex: 1 0 auto;
  padding: 30px 30px 0; // padding-bottom: 0 для padding-bottom у .buttons
}

.center {
  display: flex;
  height: 100%;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
}

.loader-icon {
  width: 64px;
}

.view__main {
  min-height: 100%;
}

.view__title {
  margin-bottom: 28px;
  font-size: 28px;
  font-weight: 500;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.title-wr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.questionnaire-history {
  @media (max-width: 960px) {
    order: -1;
    margin-bottom: 15px;
  }
}

.section-title {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.question {
  position: relative;
  margin-top: 20px;
}

.question--disabled {
  opacity: 0.5;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content: "";
  }
}

.question-text {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 27px;
}

.question-hint {
  margin-left: 5px;
}

.question-content {
  padding-left: 23px;
}

.question-description {
  font-size: 18px;
  line-height: 27px;

  ::v-deep ul {
    padding-left: 20px;
    list-style-type: disc;
  }
}

.question-table {
  max-width: 800px;
  margin-top: 40px;
}

.question-table__row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  transition: 0.3s;

  &:has(.question-table__remove-row:hover) {
    border-radius: 10px;
    background-color: rgba(255, 0, 0, 0.03);
    box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.03);
  }
}

.question-table__row--tablet {
  margin-top: 20px;
}

.question-table__header-cell {
  margin-bottom: 10px;
  color: #848484;
  font-size: 12px;
  line-height: 15px;
}

.question-table__cell {
  flex: 1 1 auto;
}

.question-table__cell--tablet {
  width: 100%;
  margin-top: 15px;
}

.question-table__cell--radio {
  margin-right: 14px;
  margin-left: 14px;
}

.question-table__remove-row {
  position: absolute;
  top: calc(50% - 6px);
  right: -20px;
  width: 12px;
  color: $red-400;
  cursor: pointer;
}

.question-table__button {
  display: inline-flex;
  padding: 5px 15px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: #7b7b7b;
  cursor: pointer;
  transition: 0.3s;
}

.question-table__button:hover {
  background-color: rgba(128, 128, 128, 0.07);
}

.question-table__button-title {
  margin-left: 5px;
  color: #515151;
  font-size: 11px;
}

.buttons {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 30px;
  background: #fff;
}

.btn {
  display: inline-flex;
  width: auto;
  height: 40px;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 19px;
  transition: 0.3s;
}

.btn--yellow {
  background: $yellow-500;
  color: $black;

  &:hover {
    filter: brightness(0.96);
  }
}

.btn--edit {
  margin-left: 12px;
}

.error-message {
  margin-top: 6px;
  color: #f75837;
  font-size: 12px;
}

.not-valid {
  margin-left: 33px;
  color: #f44f44;
}

.empty {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.empty__bg {
  display: flex;
  width: 190px;
  height: 190px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 32px;
  background-color: #ebebeb;
  color: #b7b7b7;
}

.empty__text {
  color: #333;
  font-size: 26px;
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.scroll-to {
  position: fixed;
  right: 30px;
  bottom: 40px;
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #609cd3;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s;
}

.scroll-to-icon {
  width: 14px;
  transition: 0.3s;
}

.scroll-to-icon--up {
  transform: rotate(180deg) translateY(1px);
}
