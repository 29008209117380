
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.question-checkbox {
  max-width: 800px;
  align-items: center;
  margin-bottom: 10px;
  word-break: break-word;

  ::v-deep input {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
}

.question-checkbox--highlight {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &::before {
    position: absolute;
    top: -2px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 4px);
    border-radius: 10px;
    background-color: #ffe676;
    content: "";
  }
}

.question-other {
  display: flex;
  align-items: flex-start;
}

.question-other__text {
  width: 100%;
  max-width: 700px;
  margin-left: 15px;

  ::v-deep .cab-textarea__wrapper {
    padding-top: 0; // т.к. HighlightTextarea без label - убираем паддинг, отведенный для label
  }
}
