
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              
@import "reset";
@import "scroll";
@import "templates";

.loader-ui {
  height: 80px;
  width: 80px;
  background-size: cover;
  background-image: url("@/assets/gif/cats.gif");
}
