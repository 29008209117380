
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.icon {
  display: inline-flex;
  flex: none;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable selector-pseudo-class-no-unknown */
.icon > :deep svg,
.icon > :deep img {
  width: 100%;
  height: 100%;
}
/* stylelint-enable selector-pseudo-class-no-unknown */
