
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.form {
  &__input {
    width: 260px;
    margin-bottom: 32px;
  }

  &__button {
    @include gradient-button;

    position: relative;
    width: 140px;
    height: 40px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }
}
