
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.download-icon {
  position: relative;
  z-index: 0;
  flex: none;
  cursor: pointer;
}
