
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.success-request {
  display: flex;
  max-width: 332px;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 18px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  &__description {
    margin-bottom: 22px;
    color: $grey-600;
    text-align: center;
  }

  &__button {
    @include gradient-button;

    color: $black;
  }
}
