
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.checkbox {
  display: flex;
  user-select: none;

  &#{&}--disabled {
    & * {
      cursor: default;
      filter: grayscale(1);
      opacity: 0.8;
    }
  }

  input {
    position: relative;
    width: 16px;
    min-width: 16px;
    height: 16px;
    border: 1px solid $grey-300;
    border-radius: 4px;
    margin-top: 2px;
    margin-right: 10px;
    appearance: none;
    cursor: pointer;
    outline: none;

    &:checked {
      border: none;
    }

    &:checked::after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: url("../../assets/icons/checkbox.svg");
    }
  }

  label {
    cursor: pointer;
  }
}
