
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.open-instruction-wr {
  white-space: nowrap;
}

.open-instruction {
  display: inline-flex;
  min-height: 20px;
  align-items: center;
  padding: 4px 9px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  color: #a4a4a4;
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  transition: 0.2s;

  &:hover {
    border: 1px solid #609cd3;
    background-color: #609cd3;
    color: #fff;
  }

  & + ::v-deep .app-hint {
    &__body {
      line-height: 16px;
    }
  }
}

.full-hint {
  margin-left: 7px;
}
