
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.account-view {
  --margin: 24px;
  --padding: 40px;

  position: relative;
  height: calc(100% - 2 * var(--margin));
  margin: var(--margin);

  &__main {
    position: relative;
    height: calc(100% - 56px);
    padding-top: 12px;
    border-radius: 0 10px 10px;
    background: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }

  &__content-overflow-top-border {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 1px;
    transition: background-color $shortest;

    &.visible {
      background-color: $grey-200;
    }
  }

  &__content {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
    border-radius: 0 10px 10px;
    background: white;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: $grey-300;
    }
  }

  &__force-app-installation-stub {
    display: none;
  }

  @media (max-width: 1264px) {
    --margin: 20px;
    --padding: 36px;
  }

  @media (max-width: 960px) {
    --margin: 16px;
    --padding: 32px;
  }

  @media (max-width: 600px) {
    /* stylelint-disable-next-line length-zero-no-unit */
    --margin: 0px;
    --padding: 24px;

    position: relative;

    &__main {
      display: none;
    }

    &__force-app-installation-stub {
      display: flex;
    }
  }
}
