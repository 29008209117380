
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.tabs {
  display: flex;
  width: 100%;

  &__overflow-container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    bottom: 0;
    display: block;
    height: 4px;
    background: $yellow-500;
    transition: $short;
  }

  @media (max-width: 600px) {
    &__slider {
      z-index: 2;
    }
  }
}
