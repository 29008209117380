
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.question-table__radio-wrapper {
  position: relative;
}

.question-radio {
  max-width: 800px;
  margin-bottom: 10px;
  word-break: break-word;
}

.question-radio--highlight {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &::before {
    position: absolute;
    top: -2px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 4px);
    border-radius: 10px;
    background-color: #ffe676;
    content: "";
  }

  ::v-deep .input-radio__radio,
  ::v-deep .input-radio__text {
    position: relative;
  }
}

.question-other {
  display: flex;
  align-items: flex-start;
}

.question-other__text {
  width: 100%;
  max-width: 700px;
  margin-left: 15px;

  ::v-deep .cab-textarea__wrapper {
    padding-top: 0; // т.к. HighlightTextarea без label - убираем паддинг, отведенный для label
  }
}
