
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.view {
  height: 100%;
  padding: 24px;

  &__main {
    min-height: 100%;
    padding: 20px 40px;
  }

  &__title {
    margin-bottom: 74px;
    font-size: 28px;
    font-weight: 500;
  }

  &__profile-block {
    display: flex;
    margin-bottom: 56px;
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__column {
    &--1,
    &--2 {
      width: 260px;
      margin-right: 42px;
      margin-bottom: 42px;
    }

    &--3 {
      width: 420px;
      max-width: 90%;
    }
  }

  &__column-title {
    margin-bottom: 20px;
    color: $grey-600;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__column-subtitle {
    margin-bottom: 36px;
    color: $grey-600;
    font-size: 12px;
  }

  @media (max-width: 1264px) {
    &__main {
      padding: 40px;
    }
  }

  @media (max-width: 600px) {
    padding: 0;

    &__title {
      margin-bottom: 40px;
      font-size: 24px;
    }

    &__main {
      padding: 24px;
      box-shadow: none;
    }
  }
}
