.scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d6d6d6;
  }

  /* HACK: для safari */
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* HACK: для safari */
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button:decrement,
  &::-webkit-scrollbar-button:increment {
    display: none;
  }
}
