
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
  }

  &__cancel,
  &__send {
    display: flex;
    width: 136px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__cancel {
    margin-right: 8px;
    color: $grey-700;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: $grey-800;
    }
  }

  &__send {
    width: 136px;
  }
}
