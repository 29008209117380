
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.reference-instructions {
  &__back {
    display: block;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
  }

  h1 {
    margin-bottom: 26px;
  }

  h2,
  h3,
  h4,
  p {
    margin-bottom: 16px;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 24px 0;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-end: 1em;
    margin-block-start: 1em;
    margin-inline-end: 0;
    margin-inline-start: 0;
    padding-inline-start: 40px;
  }

  ul ul {
    list-style-type: circle;
    margin-block-end: 0;
    margin-block-start: 0;
  }

  li {
    display: list-item;
    margin-bottom: 15px;
    text-align: -webkit-match-parent;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 24px;
    }
  }
}
