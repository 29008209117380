
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.tab {
  height: 100%;

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__structure {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 50px;
    }
  }

  &__add-agent {
    width: 360px;
    padding-left: 28px;
  }

  &__slider {
    width: calc(100% - 360px);
    padding: 0 48px;
  }

  &__loader-overlay {
    display: flex;
    width: 100%;
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1265px) and (max-width: 1904px) {
    // linear equation:
    // 1904*x1 + x2 = 360
    // 1265*x1 + x2 = 300
    &__add-agent {
      width: calc(10vw + 180px);
    }

    &__slider {
      width: calc(100% - 10vw - 180px);
    }
  }

  @media (min-width: 961px) and (max-width: 1264px) {
    // linear equation:
    // 1264*x1 + x2 = 360
    // 961*x1 + x2 = 300
    &__add-agent {
      width: calc(20vw + 110px);
    }

    &__slider {
      width: calc(100% - 20vw - 110px);
    }
  }

  @media (max-width: 960px) {
    &__top {
      flex-direction: column;
    }

    &__add-agent {
      width: 100%;
      margin-top: 20px;
    }

    &__slider {
      width: 100%;
      padding: 32px 24px;
    }
  }

  @media (max-width: 600px) {
    &__slider {
      padding: 24px 12px;
    }

    &__add-agent {
      width: 100%;
      padding: 0 10px;
    }
  }
}

.button-manual {
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: 0.3s;

  &:hover {
    background-color: #f6f6f6;
  }

  &__title {
    color: #1c1c1c;
    font-size: 18px;
  }

  &__download {
    width: 16px;
    margin-left: 5px;
    color: #848484;

    &:hover {
      color: #6b6b6b;
    }
  }
}

.not-found-block {
  display: flex;
  max-width: 312px;
  flex-direction: column;
  align-items: center;
  margin: 120px auto auto;

  &__fon {
    display: flex;
    width: 190px;
    height: 190px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f8f8f8;

    &__img {
      width: 100px;
      height: 100px;
    }
  }

  &__text {
    margin-top: 26px;
    font-size: 32px;
    text-align: center;
  }
}
