
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.modal-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 15px 40px;
  text-align: center;
}

.modal-title {
  margin-top: 15px;
  margin-bottom: 5px;
  color: #1c1c1c;
  font-size: 24px;
  font-weight: 500;
}

.modal-description {
  color: #848484;
  font-size: 16px;
}

.check-icon {
  color: #04c200;
}
