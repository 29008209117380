
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.switch {
  position: relative;
  width: 32px;
  height: 18px;
  border-radius: 9px;
  background-color: $grey-300;
  cursor: pointer;
  transition: background-color $shortest;

  &::after {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: white;
    content: "";
    transition: left $shortest;
  }

  &__checked {
    background-color: $yellow-500;

    &::after {
      left: 17px;
    }
  }
}
