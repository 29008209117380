
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.timezone-select {
  height: 34px;

  &__timezones-list {
    position: relative;
    z-index: 1;
    top: 14px;
  }
}
