@mixin gradient-button {
  display: flex;
  width: 100%;
  height: 48px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #ffdb33, #ffba00);
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  opacity: 0.92;
  outline: none;
  text-transform: uppercase;
  transition: opacity $shortest;
  user-select: none;

  &--blocked {
    background: $grey-100;
    color: $grey-600;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }
}
