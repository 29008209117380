
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.cab-text-field {
  width: 100%;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 34px;
    align-items: center;
    border-bottom: 1px solid $grey-300;
    margin-bottom: 6px;
    transition: border-color $short;

    &--active {
      border-bottom: 1px solid $black;
    }

    &--error {
      border-bottom: 1px solid $red-500;
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    color: $grey-600;
    transform: translate(2px, -10px);
    transform-origin: top left;
    transition: transform $shortest;

    &--active {
      transform: translateY(-36px) scale(0.8);
    }
  }

  &__wrapper--disabled {
    border-bottom: 1px solid $grey-300;
    pointer-events: none;
  }

  &__wrapper--disabled &__label {
    color: $grey-400;
  }

  &__wrapper--disabled &__input {
    color: $grey-500;
  }

  &__icon {
    display: flex;
    cursor: pointer;
  }

  &__error-message {
    color: $red-500;
    font-size: 12px;
    white-space: pre-line;
  }

  &__hint-message {
    color: $grey-500;
    font-size: 12px;
    white-space: pre-line;
  }

  &__input-wrapper {
    position: relative;
    width: 100%;
  }

  &__highlight {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__highlight-text {
    position: relative;

    * {
      position: relative;
    }

    &::before {
      position: absolute;
      top: -2px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 4px);
      border-radius: 10px;
      background-color: #ffe676;
      content: "";
    }
  }
}
