
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.block {
  &__event-type-selection {
    margin-bottom: 40px;
  }

  &__timezone-select {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  &__time-range {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__week-days {
    margin-bottom: 24px;
  }

  &__button {
    @include gradient-button;

    position: relative;
    width: 140px;
    height: 40px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }
}

.n-timezone-select {
  transition: background-color $short;

  &--highlighted {
    background-color: rgba(0, 96, 255, 0.1);
    transition: background-color $short;
  }

  &__title {
    margin-right: 40px;
  }

  &__select {
    flex: 1;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: unset;

    &__title {
      margin-bottom: 16px;
    }

    &__select {
      width: 100%;
    }
  }
}

.time-range {
  display: flex;

  span {
    margin-right: 10px;
  }

  &__text {
    margin-right: 50px;
  }

  &__inputs {
    display: flex;
    align-items: center;
  }

  &__select {
    margin-right: 20px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: unset;

    &__text {
      margin-bottom: 16px;
    }
  }
}

.week-days {
  display: flex;

  &__text {
    margin-right: 50px;
  }

  &__days {
    display: flex;
    max-height: 140px;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__day {
    margin-bottom: 12px;

    &:nth-child(1) {
      margin-right: 40px;
    }

    &:nth-child(2) {
      margin-right: 40px;
    }

    &:nth-child(3) {
      margin-right: 40px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    &__text {
      margin-bottom: 16px;
    }
  }
}
