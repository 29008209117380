
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.login-layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: white;

  &__poster {
    width: 50vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }

  &__subviews {
    display: flex;
    overflow: auto;
    width: 50vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > * {
      max-height: 100%;
    }
  }

  @media (max-width: 960px) {
    &__poster {
      display: none;
    }

    &__subviews {
      width: 100vw;
    }
  }
}
