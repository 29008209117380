
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.contract-history {
  &__title {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 500;
  }

  // TODO: fix, this class is used outside this component
  &__loader-overlay {
    display: flex;
    width: 100%;
    height: 300px;
  }

  &__loader-icon {
    display: block;
    margin: auto;
  }

  @media (max-width: 960px) {
    &__title {
      font-size: 20px;
    }
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 18px;
    }
  }
}

.contract-history-item {
  position: relative;
  display: flex;

  &__icon {
    display: flex;
    width: 44px;
    height: 44px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-300;
    border-radius: 50%;
    margin-right: 24px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &__dashed-line {
    width: 1px;
    height: calc(100% - 40px);
    margin-left: 22px;
    background: url("../../assets/icons/contract-history/dashed-line.svg");
    background-position-y: -2px;

    :last-child > * > & {
      display: none;
    }
  }

  &__text {
    display: flex;
    min-height: 40px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 6px;

    &--bold {
      font-weight: 600;
    }
  }

  &__date {
    color: $grey-600;
    font-size: 12px;
  }

  &__link {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 5px;
    margin-left: auto;
    background: $grey-50;
    color: $grey-600;
    transition: $shortest;

    &:hover {
      background: $grey-100;
      color: $grey-700;
    }
  }
}
