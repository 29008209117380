
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

/* stylelint-disable selector-class-pattern */
.tariff-contract {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 24px 24px;
}

.tariff-contract-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.tariff-contract-aside {
  width: 285px;
  flex: none;
  margin-right: 24px;

  &__confirmation {
    margin-bottom: 24px;
  }
}

.tariff-contract-documents {
  overflow: auto;
  max-height: 470px;
  padding: 15px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.tariff-contract-documents--disabled {
  opacity: 0.7;
  pointer-events: none;
}

.tariff-contract-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  color: #1c1c1c;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;

  &.all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px 12px 20px;
    border-radius: 10px;
    border-bottom: none;
    transition: 0.3s;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  img {
    margin-left: 5px;
  }

  &__body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px 12px 20px;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      background-color: #f6f6f6;
    }

    &__title {
      display: flex;
      flex-direction: column;
    }

    &__subtitle {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
    }
  }

  &__details {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 14px 12px 20px;
    border-radius: 10px;
    transition: 0.3s;

    &__notify {
      padding: 2px;
      border-radius: 2px;
      margin-right: 8px;
      background: #04c200;
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      line-height: 10px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }

    &__title {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.tariff-contract-document--active {
  border-bottom: none;
  background-color: #f6f6f6;
  font-weight: 700;
}

.tariff-contract-document-icon {
  width: 16px;
  margin-left: 5px;
  color: #848484;

  &:hover {
    color: #6b6b6b;
  }
}

.tariff-contract-documents-all {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background-color: #eee;
  }

  img {
    margin-right: 6px;
    filter: brightness(0.2);
  }

  span {
    color: #1c1c1c;
  }

  &__loader {
    filter: none;
  }
}

.tariff-contract-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: -8px;
}

.tariff-contract-info {
  display: flex;
  flex: none;
  flex-wrap: wrap;
  // margin-top: -16px;
  // margin-bottom: 20px;
  // margin-left: -16px;
  margin: -8px 8px 20px -8px;
}

.tariff-contract-status {
  display: flex;
  min-width: 450px;
  max-width: 740px;
  align-items: center;
}

.tariff-contract-status-icon {
  width: 64px;
  height: 64px;
  flex: none;
  margin-right: 40px;
}

.tariff-contract-status-right {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1800px) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }
}

.tariff-contract-status-text {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 700;

  @media (max-width: 1085px) {
    & {
      margin-left: -8px;
    }
  }
}

.tariff-contract-status-dates {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  color: #848484;
  font-size: 14px;
  line-height: 14px;

  & > div:nth-child(2) {
    margin-left: 40px;
  }
}

.tariff-contract-status-date {
  margin-top: 8px;
  color: #1c1c1c;
  font-weight: 700;
  line-height: 17px;
}

.tariff-contract-history-block {
  display: flex;
  min-width: 350px;
  max-width: 740px;
  align-items: center;
  color: #848484;
  font-size: 14px;
  line-height: 14px;
}

.tariff-contract-status,
.tariff-contract-history-block {
  flex: 1 1 0%;
  padding: 11px 24px;
  border-radius: 8px;
  margin-top: 16px;
  margin-left: 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.tariff-contract-history-link {
  position: relative;
  color: #528fc9;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  transition: 0.3s;

  &:hover {
    color: #044291;
    text-decoration: none;
  }
}

.history-box {
  position: fixed;
  z-index: 9;
  overflow: hidden;
  width: 400px;
  padding: 15px 5px 15px 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.12);
  color: #1c1c1c;
  cursor: default;
}

.history-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.history-icon {
  margin-right: 20px;
  cursor: pointer;

  ::v-deep svg path {
    transition: 0.3s;
  }

  ::v-deep &:hover svg path {
    fill: #ccc;
  }
}

.history-list {
  padding-right: 15px;
  margin-top: 30px;
}

.tariff-contract-history-block-top {
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 16px;
}

.tariff-contract-history-block-bottom {
  display: flex;
  margin-top: auto;
  margin-bottom: 11px;
}

.tariff-contract-history-block-col {
  flex: 1 1 0%;
  margin-right: 10px;
}

.tariff-contract-history-block-black {
  margin-top: 8px;
  color: #1c1c1c;
}

.tariff-contract-doc {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;

  & > img {
    position: absolute;
  }
}

.tariff-contract-text {
  width: 100%;

  ::v-deep canvas {
    margin: 6px auto;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  }
}

.tariff-contract-none {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon-wr {
    display: flex;
    width: 190px;
    height: 190px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 30px;
    background-color: #ebebeb;
  }

  &__icon {
    color: #b7b7b7;
  }

  &__title {
    margin-bottom: 10px;
    color: #333;
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }

  &__description {
    max-width: 400px;
    color: #848484;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}

.scroll--rounded {
  &::-webkit-scrollbar-thumb {
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
  }
}

.relative {
  position: relative;
  margin-top: 8px;
  margin-left: 4px;

  @media (max-width: 1085px) {
    & {
      margin-left: 0;
    }
  }
}

.flex {
  display: flex;
}

.column-status-date {
  &__title {
    padding: 5px 0;
  }
}

.justify-between {
  justify-content: space-between;
}
