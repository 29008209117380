
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.root {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: white;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-size: 17px;
    font-weight: 500;
  }

  &__link {
    margin-bottom: 6px;
  }
}
