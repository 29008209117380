
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.reference-downloads {
  display: flex;
  min-height: 500px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;

  &__group {
    width: 46%;
  }

  &__head-of-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 700;
  }

  &__os-logo {
    margin-right: 16px;
  }

  &__links {
    margin-bottom: 56px;
  }

  @media (max-width: 960px) {
    &__group {
      width: 100%;
    }
  }
}
