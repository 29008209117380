
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.reference-instructions-list {
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
  }

  &__list {
    margin-bottom: 48px;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 4px 0;
    margin-bottom: 16px;
    color: $black;

    img {
      margin-right: 18px;
    }
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 20px;
    }
  }
}
