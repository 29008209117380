
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.service-connection {
  width: 332px;
  max-width: 96%;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }

  &__help-info {
    margin-top: 24px;
    color: $grey-600;
    font-size: 13px;

    & > :first-child {
      margin-bottom: 6px;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

  &__input {
    margin-bottom: 52px;
  }

  &__send-button {
    @include gradient-button;

    margin-top: 44px;
  }
}
