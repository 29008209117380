
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.change-password {
  width: 332px;
  max-width: 96%;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

  &__instruction {
    margin-bottom: 32px;
    color: $grey-600;
  }

  &__input {
    margin-bottom: 40px;
  }

  &__email-input {
    margin-bottom: 16px;
  }
}
