
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.downloads-link {
  display: flex;
  padding: 14px 0;
  font-size: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-200;
  }

  &__description-wrapper {
    position: relative;
  }

  &__description-activator {
    width: 18px;
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity $short;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__download-link {
    margin-left: auto;
  }

  .app-hint {
    &__body {
      z-index: 2;
      width: 450px;
      max-width: 90vw;
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
}
