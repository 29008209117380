
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.reference-help {
  display: flex;
  min-height: 500px;
  padding: 24px;

  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
  }

  &__instructions-list {
    margin-bottom: 60px;
  }

  &__support-badge {
    position: sticky;
    top: 0;
    align-self: start;
    margin: 0 auto 36px;

    // watch next comment
    &.mobile {
      display: none;
    }
  }

  &__column {
    margin-right: 36px;
  }

  @media (max-width: 960px) {
    // can't use flex-direction: column-reverse
    // because of unintuitive scrollTop behaviour in that case
    flex-direction: column;

    &__support-badge {
      position: static;

      &.mobile {
        display: flex;
      }

      &:not(.mobile) {
        display: none;
      }
    }

    &__column {
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 20px;
    }
  }
}
