
                @import "@/assets/scss/_variables.scss";
                @import "@/assets/scss/mixins";
                @import "@/assets/scss/templates.scss";
              

.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition: height $shortest ease-in-out;
}
